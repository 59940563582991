<template>
  <v-container v-if="scan && results">
    <h1 class="mb-3 text-center">Accessibility Scan of {{scan.site.name}}</h1>

    <report-overview :scan="scan" :results="results" />
    <report-alerts-table :scan="scan" :results="results" />
    <report-results-pager :scan="scan" :results="results" />
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import ReportOverview from '@/components/report/ReportOverview.vue'
  import ReportAlertsTable from '@/components/report/ReportAlertsTable.vue'
  import ReportResultsPager from '@/components/report/ReportResultsPager.vue'

  export default {  
    title() {
      return this.scan && `Accessibility Scan of ${this.scan.site.name}`
    },

    components: {
      ReportOverview,
      ReportAlertsTable,
      ReportResultsPager
    },

    props: ['id'],

    computed: {
      ...mapGetters({
        scan: 'scans/scan',
        results: 'scans/results'
      })
    },

    methods: {
      ...mapActions({
        setLoading: 'setLoading',
        getScanDetails: 'scans/getScanDetails',
        getScanResults: 'scans/getScanResults'
      })
    },

    beforeRouteEnter: async function(to, from, next) {
      await next(async vm => {
        vm.setLoading(true)
        await vm.getScanDetails(to.params.id)
        await vm.getScanResults();
        vm.setLoading(false)
      })
    }
  }
</script>
