<template>
  <v-card id="#pager" class="pa-4 mb-6">
    <v-card-title class="justify-center lighten-2">
      Page Breakdown
      <v-container fluid>
        <v-select
          v-model="selectedPage"
          :items="pageDropdownOptions"
          label="Select a Page"
          @change="onSelectedPageChange"
          return-object
          outlined
          persistent-hint
          hide-details="auto"
          dense
        >
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="prevPage"
                  class="mt-n2"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
              </template>
              <span>Previous Page</span>
            </v-tooltip>
          </template>
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click="nextPage"
                  class="mt-n2"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </template>
              <span>Next Page</span>
            </v-tooltip>
          </template>
        </v-select>
      </v-container>
      <!-- {{selectedPage && selectedPage.value.title}} -->
    </v-card-title>
    <v-card-text v-if="selectedPage && selectedPage.value.accessibility.length > 0">
      <v-card v-if="selectedPage.value.accessibility.length > 0" flat class="mb-6">
        <v-card-title class="justify-center text--darken-2 font-weight-regular">
          Accessibility Violations
        </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Description</th>
                  <th>Axe Rule ID</th>
                  <th>WCAG</th>
                  <th>Impact</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(alert, index) in selectedPage.value.accessibility"
                  :key="index"
                  style="pointer-events: none"
                >
                  <td>{{index+1}}</td>
                  <td>{{alert.help}}</td>
                  <td>{{alert.id}}</td>
                  <td>{{alert.tags | wcagTags}}</td>
                  <td>{{alert.impact}}</td>
                  <td>{{alert.nodes.length}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
      <v-card v-if="selectedPage.value.accessibility.length > 0" flat>
        <v-card-title class="justify-center lighten-2 font-weight-regular">
          Accessibility Violation Details
        </v-card-title>
        <v-card-text>
          <v-expansion-panels focusable flat>
            <v-expansion-panel
              v-for="(alert, index) in selectedPage.value.accessibility"
              :key="index"
            >
              <v-expansion-panel-header>
                <v-container grid-list-md>
                  <v-row no-gutters>
                    <v-col class="col-8">
                      <h3>{{index+1}}. {{alert.help}}</h3>
                    </v-col>
                    <v-col class="col-4 text-right">
                      <a :href="alert.helpUrl" target="_blank">Learn More</a>
                    </v-col>
                  </v-row>

                  <v-row no-gutters class="mt-2">
                    <v-col class="col-8 text--secondary">
                      <b>{{alert.id}}</b>
                    </v-col>
                    <v-col class="col-4 text-right text--secondary">
                      <b>{{alert.tags | wcagTags}}</b>
                    </v-col>
                  </v-row>

                  <v-row no-gutters class="mt-4">
                    <v-col class="col-8">
                      {{alert.description}}
                    </v-col>
                    <v-col class="col-4 text-right text--secondary">
                      <b>{{alert.impact}}</b>
                    </v-col>
                  </v-row>

                  <v-row no-gutters class="mt-4 text--secondary">
                    <b>Alert Tags: {{alert.tags | commaSeparateList}}</b>
                  </v-row>
                </v-container>
                <template v-slot:actions>
                  <v-icon color="primary">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th style="width: 50%">Alert details</th>
                        <th style="width: 50%">To solve this violation, you need to...</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(node, i) in alert.nodes"
                        :key="i"
                        style="pointer-events: none"
                      >
                        <td class="py-3">{{i+1}}</td>
                        <td class="py-3">
                          <div class="mb-3">
                            <b class="d-block mb-1">Element location:</b>
                            <code class="d-block">{{node.target[0]}}</code>
                          </div>
                          <div>
                            <b class="d-block mb-1">Element source:</b>
                            <code class="d-block">{{node.html}}</code>
                          </div>
                        </td>
                        <td class="py-3">
                          <div v-html="parseFailureSummary(node.failureSummary)">
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>

      
    </v-card-text>
    <v-card-text v-if="selectedPage && selectedPage.value.accessibility.length == 0" class="text-center">
      There are no accessibility violations on this page
    </v-card-text>
  </v-card>
</template>

<script>
  import reportMixin from '@/mixins/report'

  export default {
    filters: {
      commaSeparateList: function(list) {
        return list.join(', ')
      }
    },

    mixins: [reportMixin],

    props: ['scan', 'results'],

    data: () =>  ({
      selectedPage: null
    }),

    computed: {
      pageDropdownOptions: function() {
        return this.scannedPages.map(page => {
          return {
            text: this.$options.filters.uri(page.url, this.scan.baseUrl),
            value: page
          }
        })
      }
    },

    mounted: function() {
      // If there is a hash in the URL, use that to select the page
      if (location.hash) {
        const current = this.pageDropdownOptions.find((option) => {
          return option.text === location.hash.substring(1)
        })
        this.selectedPage = current ? current : this.pageDropdownOptions[0]

        // Scroll down so that this section is in view
        const header = document.getElementsByTagName('header')[0]
        const pager = document.getElementById('#pager')
        window.scrollTo({
          top: pager.getBoundingClientRect().top - header.getBoundingClientRect().height - 16,
          behavior: 'smooth'
        });
      } else {
        this.selectedPage = this.pageDropdownOptions[0]
      }

      // Set up keyboard shortcuts
      window.addEventListener('keydown', (e) => {
        if (e.key === 'ArrowRight') {
          this.nextPage()
        } else if (e.key === 'ArrowLeft') {
          this.prevPage()
        }
      })
    },

    methods: {
      nextPage: function() {
        const index = this.pageDropdownOptions.indexOf(this.selectedPage)
        if (index < this.pageDropdownOptions.length-1) {
          this.selectedPage = this.pageDropdownOptions[index+1]
        } else {
          this.selectedPage = this.pageDropdownOptions[0]
        }
        this.onSelectedPageChange()
      },
      prevPage: function() {
        const index = this.pageDropdownOptions.indexOf(this.selectedPage)
        if (index > 0) {
          this.selectedPage = this.pageDropdownOptions[index-1]
        } else {
          this.selectedPage = this.pageDropdownOptions[this.pageDropdownOptions.length-1]
        }
        this.onSelectedPageChange()
      },
      onSelectedPageChange: function() {
        // Update the URL to include the current URI without effecting history
        history.replaceState(undefined, undefined, `#${this.selectedPage.text}`)
      },
      parseFailureSummary: function(text) {
        let output = ''
        let creatingList = false

        text.split('\n').forEach(line => {
          const trimmedLine = line.trim()
          if (trimmedLine === 'Fix any of the following:' || trimmedLine === 'Fix all of the following:') {
            if (creatingList) {
              output += '</ul>'
              creatingList = false
            }

            output += `<b>${trimmedLine}</b><br>`
          } else { 
            if (trimmedLine.length > 0) {
              if (!creatingList) {
                output += `<ul class='mb-2'>`
                creatingList = true
              }
              
              // This is used to show color previews next to HEX codes
              const trimmedLineWithColors = trimmedLine.replace(/(#[0-9a-f]{6})(\s*[\w,]+\s*)/gi, (s, g0) => {
                return `<span class="color-preview" style="background-color: ${g0}"></span>${s}`
              })
      
              output += `<li>${trimmedLineWithColors}</li>`
            }
          }
        })

        if (creatingList) {
          output += '</ul>'
          creatingList = false
        }

        return output
      }
    }
  }
</script>

<style>
  .color-preview {
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    margin-right: 0.2em;
    border-radius: 2px;
  }
</style>