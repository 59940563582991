<template>
<v-card class="pa-4 mb-6">
    <v-card-title class="justify-center">
    Most Common Alerts
    </v-card-title>
    <v-card-text>
    <v-simple-table>
        <template v-slot:default>
        <thead>
            <tr>
            <th>Description</th>
            <th>Axe Rule ID</th>
            <th>WCAG</th>
            <th>Impact</th>
            <th>Count</th>
            </tr>
        </thead>
        <tbody>
            <tr
            v-for="(alert, index) in allPageAlerts"
            :key="index"
            style="pointer-events: none"
            >
            <td>{{alert.help}}</td>
            <td>{{alert.id}}</td>
            <td>{{alert.tags | wcagTags}}</td>
            <td>{{alert.impact}}</td>
            <td>{{alert.nodes.length}}</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
    </v-card-text>
</v-card>
</template>

<script>
  import reportMixin from '@/mixins/report'

  export default {
    mixins: [reportMixin],

    props: ['scan', 'results'],

    computed: {
      allPageAlerts: function() {
        const output = []

        // Loop through combining all of the alerts into a single array
        for (let transaction of this.results) {
          if (!transaction.accessibility)
            continue

          for (let alert of transaction.accessibility) {
            const exists = output.find(item => item.id === alert.id)

            if (exists) {
              const index = output.indexOf(exists)
              output[index].nodes = output[index].nodes.concat(alert.nodes)
            } else {
              output.push(Object.assign({}, alert))
            }
          }
        }

        // Sort output by node count
        output.sort((a, b) => {
          return b.nodes.length - a.nodes.length
        })

        return output
      }
    }
  }
</script>