<template>
  <v-card class="pa-4 mb-6">
    <v-card-title class="justify-center">
      Report Overview
    </v-card-title>
    <v-card-subtitle class="text-center">
      Scanned {{moment(scan.timestamp).format('MMMM D, YYYY - h:mm:ss a')}}
    </v-card-subtitle>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" sm="3">
          <v-card outlined class="mx-2">
            <v-card-title class="text-body-1 justify-center">
              Pages Scanned
            </v-card-title>
            <v-card-text class="text-h5 text-center primary--text">
              {{scannedPages.length}}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card outlined class="mx-2">
            <v-card-title class="text-body-1 justify-center">
              Critical Alerts
            </v-card-title>
            <v-card-text class="text-h5 text-center primary--text">
              {{severityTotal('critical')}}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card outlined class="mx-2">
            <v-card-title class="text-body-1 justify-center">
              Serious Alerts
            </v-card-title>
            <v-card-text class="text-h5 text-center primary--text">
              {{severityTotal('serious')}}
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="3">
          <v-card outlined class="mx-2">
            <v-card-title class="text-body-1 justify-center">
              Moderate Alerts
            </v-card-title>
            <v-card-text class="text-h5 text-center primary--text">
              {{severityTotal('moderate')}}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import reportMixin from '@/mixins/report'

  export default {
    mixins: [reportMixin],

    props: ['scan', 'results'],

    methods: {
      severityTotal: function (severity) {
        if (!this.results || this.results.length == 0)
          return 0

        let count = 0
        for (let transaction of this.results) {
          if (!transaction.accessibility)
            continue

          for (let alert of transaction.accessibility) {
            if (alert.impact === severity) {
              count += alert.nodes.length
            }
          }
        }

        return count
      }
    }
  }
</script>