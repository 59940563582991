export default {
  computed: {
    scannedPages: function () {
      return this.results.filter(function (result) {
        return result.scanned && result.accessibilityS3Key
      })
    }
  },
  filters: {
    uri: function (url, baseUrl) {
      let uri;
      if (url.startsWith('https')) {
        uri = url.replace(baseUrl, '');
      } else {
        uri = url.replace(baseUrl.replace('https', 'http'), '');
      }
      return uri.length > 0 ? uri : '/';
    },
    wcagTags: function (tags) {
      if (tags.includes('best-practice')) {
        return 'Best practice';
      }

      const wcagTags = tags.filter(tag => tag.includes('wcag'));
      if (wcagTags.length > 0) {
        return wcagTags.map(tag => {
          // Get the WCAG section number (e.g 2 or 412)
          const sectionNumberMatch = tag.match(/\d+/)
          const sectionNumber = sectionNumberMatch && sectionNumberMatch.length >= 1
              ? sectionNumberMatch[0].split('').join('.')
              : ''

          // Get the WCAG level (e.g AA or AAA)
          const levelMatch = tag.match(/wcag\d+(a+)/);
          const level = levelMatch && levelMatch.length > 1
              ? ` Level ${levelMatch[1].toUpperCase()}`
              : ''

          return `WCAG ${sectionNumber}${level}`;
        }).join(', ')
      }

      return tags.join(', ')
    }
  }
}
